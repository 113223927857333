/* Estilos generales de la página */

:root{
    /* paleta de colores */
    --light: #ffffff;
    --green: #084e46;
    --green-light: #094e4638;
  
  }
  
  
  * {
    margin: 0px;
    padding: 0px;
  }
  
  .body {
    background-color: #ffffff;
    font-family: sans-serif;
    margin: 0px;
    padding: 0px;
  }
  
  .modal-content {
    animation-name: modalAnim;
    animation-duration: 0.2s;
  }
  
  @keyframes modalAnim {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Estilos generales para las 8 secciones de la navbar */
  
  .seccion {
    margin-top: 120px;
    padding: 0px 50px;
  }
  
  .seccion .titulo-seccion {
    color: #084e46;
    font-size: 2.5rem;
    text-align: center;
  }
  
  .seccion .parrafo-seccion {
    text-align: justify;
    font-size: 1.2rem;
  }
  
  .seccion ol,
  .seccion ul {
    font-size: 1.2rem;
  }
  
  .seccion ol li,
  .seccion ul li {
    text-align: justify;
  }
  
  @media only screen and (max-width: 576px) {
    .seccion .titulo-seccion {
      font-size: 1.7rem;
    }
  
    .seccion {
      padding: 0px 20px;
    }
  
    .seccion {
      margin-top: 100px;
    }
  }
